<template>
	<v-expansion-panels flat v-model="panel">
		<v-expansion-panel>
			<v-expansion-panel-header
				expand-icon=""
				color="grey"
				:class="$vuetify.theme.dark ? 'darken-4' : 'lighten-5'"
				disable-icon-rotate
			>
				<template v-slot:actions>
					<div>
						<v-btn
							class="text-capitalize"
							small
							dark
							color="red darken-1 mr-2"
							@click.stop="clearFilterPayload"
							v-if="isFilterApplied"
						>
							<v-icon left v-text="'fas fa-times'" color="white" x-small></v-icon>
							Filtreleri temizle
						</v-btn>
					</div>
					<div>
						<v-btn class="text-capitalize" small light color="warning">
							<v-icon left color="white" v-text="'fas fa-filter'" x-small></v-icon>
							Filtreler
						</v-btn>
					</div>
				</template>
			</v-expansion-panel-header>
			<v-expansion-panel-content class="py-5">
				<v-row dense>
					<v-col cols="12" md="1">
						<v-select
							:items="getYears"
							outlined
							label="Yıl Seçiniz"
							v-model="filter.year"
							item-text="value"
							item-value="id"
							@change="yearChanged"
							hide-details="auto"
							dense
						></v-select>
					</v-col>
					<v-col cols="12" md="2">
						<v-select
							:items="getBrands"
							outlined
							label="Marka Seçiniz"
							v-model="filter.brand"
							item-text="value"
							item-value="id"
							@change="brandChanged"
							return-object
							hide-details="auto"
							:disabled="brandIsDisabled"
							dense
						></v-select>
					</v-col>
					<v-col cols="12" md="2">
						<v-select
							:items="getModels"
							outlined
							label="Model Seçiniz"
							v-model="filter.model"
							item-text="value"
							item-value="id"
							@change="modelChanged"
							return-object
							:disabled="modelIsDisabled"
							hide-details="auto"
							dense
						></v-select>
					</v-col>
					<v-col cols="12" md="2">
						<v-select
							:items="getBodyTypes"
							outlined
							label="Kasa Tipi Seçiniz"
							v-model="filter.bodyType"
							item-text="value"
							item-value="id"
							@change="bodyTypeChanged"
							return-object
							:disabled="bodyTypeIsDisabled"
							hide-details="auto"
							dense
						></v-select>
					</v-col>
					<v-col cols="12" md="2">
						<v-select
							:items="getTransmissionTypes"
							outlined
							label="Vites Seçiniz"
							v-model="filter.transmissionType"
							item-text="value"
							item-value="id"
							@change="transmissionTypeChanged"
							return-object
							:disabled="transmissionTypeIsDisabled"
							hide-details="auto"
							dense
						></v-select>
					</v-col>
					<v-col cols="12" md="1">
						<v-select
							:items="getFuelTypes"
							outlined
							label="Yakıt Seçiniz"
							v-model="filter.fuelType"
							item-text="value"
							item-value="id"
							@change="fuelTypeChanged"
							return-object
							:disabled="fuelTypeIsDisabled"
							hide-details="auto"
							dense
						></v-select>
					</v-col>
					<v-col cols="12" md="2">
						<v-select
							:items="getVersions"
							outlined
							label="Versiyon Seçiniz"
							v-model="filter.version"
							item-text="value"
							item-value="id"
							return-object
							:disabled="versionIsDisabled"
							hide-details="auto"
							dense
						></v-select>
					</v-col>
					<v-col cols="12" md="3">
						<v-text-field
							label="Talep No"
							outlined
							hide-details="auto"
							flat
							dense
							v-model="filter.trackingNumber"
						></v-text-field>
					</v-col>
					<v-col cols="12" md="3">
						<v-text-field
							label="Müşteri"
							outlined
							hide-details="auto"
							flat
							dense
							v-model="filter.customerName"
						></v-text-field>
					</v-col>
					<v-col cols="12" md="2">
						<v-text-field
							label="Yetkili"
							outlined
							hide-details="auto"
							flat
							dense
							v-model="filter.supervisorName"
						></v-text-field>
					</v-col>
					<v-col cols="12" md="2" v-if="showStatusFilter">
						<v-select
							:items="statusList"
							outlined
							label="Durumu"
							v-model="filter.statusId"
							item-text="value"
							item-value="id"
							hide-details="auto"
							dense
						></v-select>
					</v-col>
					<v-col cols="12" md="1">
						<v-text-field
							label="Min KM"
							outlined
							hide-details="auto"
							flat
							dense
							v-model="filter.kilometerMin"
						></v-text-field>
					</v-col>
					<v-col cols="12" md="1">
						<v-text-field
							label="Max KM"
							outlined
							hide-details="auto"
							flat
							dense
							v-model="filter.kilometerMax"
						></v-text-field>
					</v-col>
					<v-col cols="12" md="1">
						<v-text-field
							label="Plaka"
							outlined
							hide-details="auto"
							flat
							dense
							v-model="filter.plateNumber"
						></v-text-field>
					</v-col>
					<v-col cols="12" md="2">
						<v-text-field
							label="Müşteri Tel"
							outlined
							hide-details="auto"
							flat
							dense
							v-model="filter.customerPhone"
						></v-text-field>
					</v-col>
					<v-col cols="12" md="2">
						<v-text-field
							label="Müşteri E-posta"
							outlined
							hide-details="auto"
							flat
							dense
							v-model="filter.customerEmail"
						></v-text-field>
					</v-col>
					<v-col cols="12" md="2">
						<v-text-field
							label="Min SmartIQ Fiyat"
							outlined
							hide-details="auto"
							flat
							dense
							v-model="filter.retailPriceMin"
						></v-text-field>
					</v-col>
					<v-col cols="12" md="2">
						<v-text-field
							label="Max SmartIQ Fiyat"
							outlined
							hide-details="auto"
							flat
							dense
							v-model="filter.retailPriceMax"
						></v-text-field>
					</v-col>

					<v-col cols="12" md="2">
						<v-text-field
							label="Min Teklif Tutar"
							outlined
							hide-details="auto"
							flat
							dense
							v-model="filter.galleryPriceMin"
						></v-text-field>
					</v-col>
					<v-col cols="12" md="2">
						<v-text-field
							label="Max Teklif Tutar"
							outlined
							hide-details="auto"
							flat
							dense
							v-model="filter.galleryPriceMax"
						></v-text-field>
					</v-col>
					<v-col cols="12" md="2">
						<v-menu transition="scale-transition" offset-y min-width="auto">
							<template v-slot:activator="{ on, attrs }">
								<v-text-field
									outlined
									flat
									dense
									v-model="filter.startDate"
									label="Min Tarih"
									hide-details="auto"
									readonly
									v-bind="attrs"
									v-on="on"
								>
									<template v-slot:append>
										<v-icon class="ma-1" small v-text="'fa-calendar-alt'"></v-icon>
									</template>
								</v-text-field>
							</template>
							<v-date-picker no-title v-model="filter.startDate"></v-date-picker>
						</v-menu>
					</v-col>
					<v-col cols="12" md="2">
						<v-menu transition="scale-transition" offset-y min-width="auto">
							<template v-slot:activator="{ on, attrs }">
								<v-text-field
									outlined
									flat
									dense
									v-model="filter.endDate"
									label="Max Tarih"
									hide-details="auto"
									readonly
									v-bind="attrs"
									v-on="on"
								>
									<template v-slot:append>
										<v-icon class="ma-1" small v-text="'fa-calendar-alt'"></v-icon>
									</template>
								</v-text-field>
							</template>
							<v-date-picker no-title v-model="filter.endDate"></v-date-picker>
						</v-menu>
					</v-col>
					<v-col cols="12" md="2">
						<v-select
							:items="getExchangeParams"
							outlined
							label="Takas Talebi"
							v-model="filter.isExchangeRequested"
							item-text="value"
							item-value="id"
							hide-details="auto"
							dense
						></v-select>
					</v-col>
					<v-col cols="12" md="3" v-if="showAssignedToMe">
						<v-col lg="10" cols="12" class="pt-0">
							<v-checkbox
								color="teal"
								v-model="filter.isAssignToMe"
								label="Bana Atananlar"
								hide-details="auto"
							></v-checkbox>
						</v-col>
					</v-col>
				</v-row>
				<v-row dense>
					<v-col cols="12" class="text-right mt-1">
						<v-btn small color="teal" class="text-capitalize" dark @click="filterBind(false)">Filtre Uygula</v-btn>
					</v-col>
				</v-row>
			</v-expansion-panel-content>
		</v-expansion-panel>
	</v-expansion-panels>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { GET_WIZARD_YEARS, GET_WIZARD_DATA } from '@/store/modules/carWizard.module';
import { CLEAR_FILTER, SET_FILTER_ACTIVE } from '@/store/modules/carInfo.module';

const defaultPayload = () => ({
	year: 0,
	brand: 0,
	model: 0,
	bodyType: 0,
	transmissionType: 0,
	fuelType: 0,
	version: 0,
	trackingNumber: null,
	customerName: null,
	supervisorName: null,
	statusId: 0,
	kilometerMin: 0,
	kilometerMax: 0,
	retailPriceMin: 0,
	retailPriceMax: 0,
	galleryPriceMin: 0,
	galleryPriceMax: 0,
	startDate: null,
	endDate: null,
	isExchangeRequested: null,
	isAssignToMe: false,
	plateNumber: null,
	customerPhone: null,
	customerEmail: null,
});

export default {
	name: 'CarInfoFilter',
	props: {
		gridPreset: {
			type: Object,
			required: true,
		},
		assignedToMe: {
			type: Boolean,
			required: false,
			default: false,
		},
		showStatus: {
			type: Boolean,
			required: false,
			default: false,
		},
	},
	data() {
		return {
			panel: null,
		};
	},
	computed: {
		...mapGetters([
			'getYears',
			'getBrands',
			'getModels',
			'getBodyTypes',
			'getTransmissionTypes',
			'getFuelTypes',
			'getVersions',
			'filterTmpPayload',
			'isFilterApplied',
		]),
		panelIsActive() {
			return this.panel === null ? null : 0;
		},
		statusList() {
			return this.gridPreset?.statusList;
		},
		brandIsDisabled() {
			return this.getBrands.length === 0;
		},
		modelIsDisabled() {
			return this.getModels.length === 0;
		},
		bodyTypeIsDisabled() {
			return this.getBodyTypes.length === 0;
		},
		transmissionTypeIsDisabled() {
			return this.getTransmissionTypes.length === 0;
		},
		fuelTypeIsDisabled() {
			return this.getFuelTypes.length === 0;
		},
		versionIsDisabled() {
			return this.getVersions.length === 0;
		},
		getExchangeParams() {
			const result = [];

			result.push({ id: null, value: 'Hepsi' });
			result.push({ id: true, value: 'Evet' });
			result.push({ id: false, value: 'Hayır' });

			return result;
		},
		filter() {
			return this.filterTmpPayload !== null ? this.filterTmpPayload : defaultPayload();
		},
		showStatusFilter() {
			return this.showStatus;
		},
		showAssignedToMe() {
			return this.assignedToMe;
		},
	},
	created() {
		this.$nextTick(() => {
			this.getWizardYears();
		});
	},
	methods: {
		...mapActions({
			getWizardYears: GET_WIZARD_YEARS,
			getWizardData: GET_WIZARD_DATA,
			clearFilter: CLEAR_FILTER,
			setFilterActive: SET_FILTER_ACTIVE,
		}),
		clearFilterPayload() {
			this.clearFilter().then(() => {
				this.setFilterActive(false);

				this.filterBind(true);
			});
		},
		filterBind(isClear) {
			const payload = {
				...this.filter,
				brandId: this.filter.brand?.id ?? 0,
				modelId: this.filter.model?.id ?? 0,
				bodyTypeId: this.filter.bodyType?.id ?? 0,
				transmissionTypeId: this.filter.transmissionType?.id ?? 0,
				fuelTypeId: this.filter.fuelType?.id ?? 0,
				versionId: this.filter.version?.id ?? 0,
				trackingNumber: this.filter.trackingNumber,
				statusId: this.filter.statusId ?? 0,
				kilometerMin: this.filter.kilometerMin ?? 0,
				kilometerMax: this.filter.kilometerMax ?? 0,
				retailPriceMin: this.filter.retailPriceMin ?? 0,
				retailPriceMax: this.filter.retailPriceMax ?? 0,
				galleryPriceMin: this.filter.galleryPriceMin ?? 0,
				galleryPriceMax: this.filter.galleryPriceMax ?? 0,
				staticStatus: this.filter.statusId?.id ?? 0,
			};

			if (!isClear) this.setFilterActive(true);
			// this.panel = isClear ? null : 0;
			this.$emit('filterBind', payload);
		},
		yearChanged(year) {
			const payload = {
				year,
			};
			this.getWizardData({ payload, fillArrayField: 'brands' }).then(() => {
				this.brandChanged();
			});
		},
		brandChanged() {
			if (this.filter.brand === null) return;

			const payload = {
				year: this.filter.year,
				brandId: this.filter.brand?.id,
			};
			this.getWizardData({ payload, fillArrayField: 'models' }).then(() => {
				if (this.filter.model) {
					if (!this.getModels.some((x) => x.id === this.filter.model.id)) {
						this.filter.model = null;
						return;
					}
				}

				this.modelChanged();
			});
		},
		modelChanged() {
			if (this.filter.model === null) return;

			const payload = {
				year: this.filter.year,
				brandId: this.filter.brand.id,
				modelId: this.filter.model.id,
			};
			this.getWizardData({ payload, fillArrayField: 'bodyTypes' }).then(() => {
				if (this.filter.bodyType) {
					if (!this.getBodyTypes.some((x) => x.id === this.filter.bodyType.id)) {
						this.filter.bodyType = null;
						return;
					}
				}

				this.bodyTypeChanged();
			});
		},
		bodyTypeChanged() {
			if (this.filter.bodyType === null) return;

			const payload = {
				year: this.filter.year,
				brandId: this.filter.brand.id,
				modelId: this.filter.model.id,
				bodyTypeId: this.filter.bodyType.id,
			};
			this.getWizardData({ payload, fillArrayField: 'transmissionTypes' }).then(() => {
				if (this.filter.transmissionType) {
					if (!this.getTransmissionTypes.some((x) => x.id === this.filter.transmissionType.id)) {
						this.filter.transmissionType = null;
						return;
					}
				}

				this.transmissionTypeChanged();
			});
		},
		transmissionTypeChanged() {
			if (this.filter.transmissionType === null) return;

			const payload = {
				year: this.filter.year,
				brandId: this.filter.brand.id,
				modelId: this.filter.model.id,
				bodyTypeId: this.filter.bodyType.id,
				transmissionTypeId: this.filter.transmissionType.id,
			};
			this.getWizardData({ payload, fillArrayField: 'fuelTypes' }).then(() => {
				if (this.filter.fuelType) {
					if (!this.getFuelTypes.some((x) => x.id === this.filter.fuelType.id)) {
						this.filter.fuelType = null;
						return;
					}
				}

				this.fuelTypeChanged();
			});
		},
		fuelTypeChanged() {
			if (this.filter.fuelType === null) return;

			const payload = {
				year: this.filter.year,
				brandId: this.filter.brand.id,
				modelId: this.filter.model.id,
				bodyTypeId: this.filter.bodyType.id,
				transmissionTypeId: this.filter.transmissionType.id,
				fuelTypeId: this.filter.fuelType.id,
			};
			this.getWizardData({ payload, fillArrayField: 'versions' }).then(() => {
				if (this.filter.version) {
					if (!this.getVersions.some((x) => x.id === this.filter.version.id)) {
						this.filter.version = null;
					}
				}
			});
		},
	},
};
</script>

<style></style>
